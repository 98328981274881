import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { PortalWithState } from 'react-portal';
import ScrollLock from 'react-scrolllock';
import clsx from 'clsx';

import ContactForm from '../../components/ContactForm';
import Button from '../../shared/Button';
import Overlay from '../../shared/Overlay';

import * as styles from './CallToAction.module.css';

CallToAction.propTypes = {
  data: PropTypes.shape().isRequired,
  contactData: PropTypes.shape().isRequired,
};
CallToAction.defaultProps = {};

function CallToAction({ data, contactData }) {
  const { title, text, buttonLabel } = data;
  const { telephone } = contactData;

  const { t } = useTranslation('calltoaction');

  return (
    <div className={clsx(styles.CallToAction, 'defaultWidth')}>
      {title && <h3>{title}</h3>}
      {text && <strong dangerouslySetInnerHTML={{ __html: text }} />}
      <div>
        <Button href={`tel:${telephone}`}>{telephone}</Button>
        <PortalWithState
          closeOnOutsideClick
          closeOnEsc
          node={typeof document !== 'undefined' && document.querySelector('#portal')}
        >
          {({ openPortal, closePortal, portal }) => (
            <React.Fragment>
              <Button onClick={openPortal}>{buttonLabel || t('buttonlabel.default')}</Button>
              {portal(
                <React.Fragment>
                  <ScrollLock />
                  <Overlay closePortal={closePortal}>
                    <ContactForm mailId="nhhydraulikzylinder" />
                  </Overlay>
                </React.Fragment>,
              )}
            </React.Fragment>
          )}
        </PortalWithState>
      </div>
    </div>
  );
}

export default CallToAction;
