import React from 'react';
import clsx from 'clsx';

import pdfIcon from '../assets/images/flatiron-file-type-icons/png/pdf.png';
import aiIcon from '../assets/images/flatiron-file-type-icons/png/ai.png';
import docIcon from '../assets/images/flatiron-file-type-icons/png/doc.png';
import jpgIcon from '../assets/images/flatiron-file-type-icons/png/jpg.png';
import pngIcon from '../assets/images/flatiron-file-type-icons/png/png.png';
import pptIcon from '../assets/images/flatiron-file-type-icons/png/ppt.png';
import zipIcon from '../assets/images/flatiron-file-type-icons/png/zip.png';

import Ansprechpartner from '../components/Ansprechpartner';
import { useTranslation } from 'react-i18next';
import Quote from './Quote';
import * as styles from './ProductDescription.module.css';

ProductDescription.propTypes = {};
ProductDescription.defaultProps = {};

const fileTypeMapping = {
  pdf: pdfIcon,
  ai: aiIcon,
  doc: docIcon,
  jpg: jpgIcon,
  png: pngIcon,
  ppt: pptIcon,
  zip: zipIcon,
};

function ProductDescription({ data, contacts, twoColumns, quote, label }) {
  const { t } = useTranslation('downloads');

  if (!data || data.length === 0) {
    return null;
  }

  return data.map((productDescription, index) => {
    const { title, text, title_advantages: advantagesTitle, advantages, downloads } = productDescription;

    const classNames = clsx(styles.ProductDescription, data.length > 1 && styles.multiple);

    if (twoColumns) {
      return (
        <section key={title} className={classNames}>
          {index === 0 && label && (!contacts || contacts.length !== 1) && <h2>{label}</h2>}
          <main>
            {index === 0 && label && contacts && contacts.length === 1 && <h2>{label}</h2>}
            {title && <h3>{title}</h3>}
            {text && (
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: text,
                }}
              />
            )}
            {quote && index + 1 === data.length && <Quote data={quote} />}
          </main>
          <aside>
            {index < 1 && contacts && contacts.length === 1 && <Ansprechpartner data={contacts} />}
            {advantagesTitle && <h3>{advantagesTitle}</h3>}
            {advantages && (
              <div
                className={styles.text}
                dangerouslySetInnerHTML={{
                  __html: productDescription.advantages,
                }}
              />
            )}
            {downloads && downloads.length > 0 && (
              <React.Fragment>
                <h3>{t('title')}</h3>
                <ul style={{ paddingLeft: 10 }}>
                  {downloads.map(({ label: downloadLabel, file }) => (
                    <li
                      key={file.publicURL}
                      style={{
                        listStyleType: 'none',
                        background: `transparent url('${fileTypeMapping[file.extension]}') no-repeat`,
                        backgroundSize: '22px 22px',
                        paddingLeft: 35,
                        marginTop: 10,
                      }}
                    >
                      <a href={file.publicURL} target="_blank" rel="noopener noreferrer">
                        {downloadLabel}
                      </a>
                    </li>
                  ))}
                </ul>
              </React.Fragment>
            )}
          </aside>
          {index + 1 === data.length && contacts && contacts.length > 1 && <Ansprechpartner data={contacts} />}
        </section>
      );
    }
    return (
      <section key={title} className={classNames}>
        {index === 0 && label && <h2>{label}</h2>}
        {title && <h3>{title}</h3>}
        {text && (
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: text,
            }}
          />
        )}

        {advantagesTitle && <h3>{advantagesTitle}</h3>}
        {advantages && (
          <div
            className={styles.text}
            dangerouslySetInnerHTML={{
              __html: productDescription.advantages,
            }}
          />
        )}

        {downloads && downloads.length > 0 && (
          <React.Fragment>
            <h3>{t('title')}</h3>
            <ul style={{ paddingLeft: 10 }}>
              {downloads.map(({ label: downloadLabel, file }) => (
                <li
                  key={file.publicURL}
                  style={{
                    listStyleType: 'none',
                    background: `transparent url('${fileTypeMapping[file.extension]}') no-repeat`,
                    backgroundSize: '22px 22px',
                    paddingLeft: 35,
                    marginTop: 10,
                  }}
                >
                  <a href={file.publicURL} target="_blank" rel="noopener noreferrer">
                    {downloadLabel}
                  </a>
                </li>
              ))}
            </ul>
          </React.Fragment>
        )}

        {quote && index + 1 === data.length && <Quote data={quote} />}

        {contacts && contacts.length > 0 && index + 1 === data.length && <Ansprechpartner data={contacts} />}
      </section>
    );
  });
}

export default ProductDescription;
