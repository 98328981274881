import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Contact from './Contact';
import * as styles from './Ansprechpartner.module.css';

Ansprechpartner.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
Ansprechpartner.defaultProps = {};

function Ansprechpartner({ data }) {
  const { t } = useTranslation();
  return (
    <section className={styles.Ansprechpartner}>
      <h3>{t('contact')}</h3>
      <ul>
        {data.map((contact) => (
          <Contact key={contact.name} data={contact} />
        ))}
      </ul>
    </section>
  );
}

export default Ansprechpartner;
