import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import Image from '../../shared/Image';

import SliderParent from './SliderParent/SliderParent';
import * as styles from './ImageGallery.module.css';

const aspectRatio = 3 / 2;

ImageGallery.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()),
};

ImageGallery.defaultProps = {
  data: [],
};

function ImageGallery({ data }) {
  return (
    <div>
      <SliderParent
        className={styles.ImageGallerySlider}
        leftSVGIcon={
          <svg viewBox="0 0 41 52.7">
            <polygon points="0 26.35 41 52.7 41 0 0 26.35" />
          </svg>
        }
        showButtons
        showPosSelector
        buttonsClass={styles.buttons}
      >
        {data.map((node) => (
          <div key={node.image.id} className={styles.imageWrapper}>
            <div>
              <Link to={node.link}>
                <Image data={node} aspectRatio={aspectRatio} />
                {node.title && <div className={styles.imageText}>{node.title}</div>}
              </Link>
            </div>
          </div>
        ))}
      </SliderParent>
    </div>
  );
}

export default ImageGallery;
