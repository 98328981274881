import React from 'react';
import { string, node, oneOf, oneOfType, arrayOf } from 'prop-types';
import { Link as GatsbyLink } from 'gatsby';
import classnames from 'classnames';

import './Button.css';
import { capitalize } from '../../utils/StringUtils';

Button.propTypes = {
  // eslint-disable-next-line react/no-typos
  /* The target where the button should link too;
   * if set the button will be rendered as an <a> tag. */
  href: string,
  tagName: oneOf(['a', 'button']),
  target: oneOf(['_blank', '_self', '_parent', '_top']),
  theme: string,
  className: string,
  children: oneOfType([node, arrayOf(node)]).isRequired,
};
Button.defaultProps = {
  tagName: null,
  href: null,
  target: null,
  theme: null,
  className: null,
};

function determineTagName(tagName, href, external) {
  if (href) {
    return external ? 'a' : GatsbyLink;
  }
  return tagName || 'button';
}

function Button({ href, target, tagName, theme, children, className, ...rest }) {
  const external =
    href && (href.indexOf('http://') === 0 || href.indexOf('https://') === 0 || href.indexOf('tel:') === 0);
  const TagName = determineTagName(tagName, href, external);

  const classNames = classnames('MAButton', {
    [className]: className,
    '-internal': TagName === GatsbyLink,
    '-external': TagName === 'a',
    '-button': TagName === 'button',
    [`-theme${capitalize(theme)}`]: theme,
  });

  const attrs = {
    className: classNames,
    to: href && external ? undefined : href,
    href: href && external ? href : undefined,
    target: target || (href && external ? '_blank' : undefined),
  };

  return (
    <TagName {...attrs} {...rest}>
      {children}
    </TagName>
  );
}

export default Button;
