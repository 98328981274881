import Image from '../../shared/Image';
import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Contact.module.css';

Contact.propTypes = {
  data: PropTypes.shape().isRequired,
};
Contact.defaultProps = {};

function Contact({ data }) {
  const { name, jobTitle, alternateJobTitle, telephone, email, image } = data;
  return (
    <li className={styles.Contact}>
      <span className={styles.image}>
        <Image data={image} alt={name} />
      </span>
      <span className={styles.contactData}>
        <strong>{name}</strong>
        {(alternateJobTitle || jobTitle) && <span>{alternateJobTitle || jobTitle}</span>}
        {email && <a href={`mailto:${email}`}>{email}</a>}
        {telephone && <a href={`tel:${telephone}`}>{telephone}</a>}
      </span>
    </li>
  );
}

export default Contact;
