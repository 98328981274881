import ImageGallery from '../../components/SliderGalleries/ImageGallery';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Slugger from '../../utils/Slugger';
import * as styles from './ProductTeaserBlock.module.css';

ProductTeaserBlock.propTypes = {
  title: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};
ProductTeaserBlock.defaultProps = {
  title: null,
};

function ProductTeaserBlock({ title, data }) {
  const { i18n } = useTranslation();
  const sliderData = data.map(({ image, label }) => ({
    image,
    title: label,
    link: i18n.language === 'en' ? `/en/products/${Slugger.slug(label)}` : `/produkte/${Slugger.slug(label)}`,
  }));

  return (
    <div className={styles.ProductTeaserBlock}>
      {title && <h3>{title}</h3>}
      <ImageGallery data={sliderData} />
    </div>
  );
}

export default ProductTeaserBlock;
