import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './Quote.module.css';

Quote.propTypes = {
  data: PropTypes.shape({
    quote: PropTypes.string,
    originator: PropTypes.string,
  }),
};
Quote.defaultProps = {
  data: null,
};

function Quote({ data }) {
  if (!data) {
    return null;
  }

  const { quote, originator } = data;
  if (!quote || quote.length <= 0) {
    return null;
  }
  return (
    <div className={styles.Quote}>
      <blockquote>
        <main>{quote}</main>
        {originator && <footer>{originator}</footer>}
      </blockquote>
    </div>
  );
}

export default Quote;
