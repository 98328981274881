import React from 'react';
import PropTypes from 'prop-types';

import BackgroundImage from '../../components/BackgroundImage';
import WidthDependentProps from '../../components/WidthDependentProps';
import FlexibleHeightContainer from '../../components/FlexibleHeightContainer';
import CallToActionComponent from '../../components/CallToAction';
import OverlayPositioning from '../../shared/OverlayPositioning';

import * as styles from './CallToAction.module.css';

CallToAction.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    text: PropTypes.string,
    buttonLabel: PropTypes.string,
    image: PropTypes.shape(),
  }).isRequired,
  contactData: PropTypes.shape({
    telephone: PropTypes.string,
  }).isRequired,
  objectFit: PropTypes.string,
  maxHeight: PropTypes.number,
};
CallToAction.defaultProps = {
  objectFit: 'contain',
  maxHeight: 600,
};

function CallToAction(props) {
  const { data, objectFit, maxHeight } = props;
  const { image, published } = data;

  if (!published || (published && !published)) {
    return null;
  }

  return (
    <WidthDependentProps
      defaultProps={{
        overflow: 'hidden',
      }}
      responsiveProps={{
        default: {
          maxHeight,
          aspectRatio: 2,
        },
        mobile: {
          aspectRatio: 1.25,
          maxHeight: -1,
        },
        400: {
          // adapt height to content
        },
      }}
    >
      {(width, sizeDependentProps) => (
        <FlexibleHeightContainer Tag="section" className={styles.CallToAction} width={width} {...sizeDependentProps}>
          <BackgroundImage image={image} backgroundColor="white" objectFit={width > 1200 ? objectFit : 'cover'} />
          <OverlayPositioning textAlign="center" verticalAlign="center" positionRelative={width < 400}>
            <CallToActionComponent {...props} />
          </OverlayPositioning>
        </FlexibleHeightContainer>
      )}
    </WidthDependentProps>
  );
}

export default CallToAction;
