import React from 'react';
import PropTypes from 'prop-types';

import * as styles from './SliderParent.module.css';

// elemWrappers can be refactored

export default class SliderParent extends React.Component {
  static propTypes = {
    className: PropTypes.string, // override some css
    buttonsClass: PropTypes.string,
    autoSlide: PropTypes.bool,
    autoSlideDelay: PropTypes.number,
    leftButton: PropTypes.shape(),
    rightButton: PropTypes.shape(),
    leftSVGIcon: PropTypes.shape(),
    children: PropTypes.arrayOf(PropTypes.node),
    style: PropTypes.shape(),
    showButtons: PropTypes.bool,
  };

  static defaultProps = {
    buttonsClass: '',
    className: '',
    autoSlide: false,
    autoSlideDelay: 10000,
    children: [],
    leftButton: null,
    rightButton: null,
    leftSVGIcon: null,
    showButtons: true,
    style: null,
  };

  timerTime = 0;

  tickTime = 200;

  blockTimerSlide = false;

  constructor(props) {
    super(props);

    const images = Array.isArray(props.children) ? props.children : [props.children];

    // swipe events
    this.xDown = null;
    this.yDown = null;
    // refs
    this.arrayOfElemsRef = [];
    // state
    this.state = {
      randomId: Math.round(Math.random() * 999999),
      activePos: 0,
      maxTimesSlide: 0,
      imagesLeft: 0,
      imagesArray: images,
    };
  }

  componentDidMount() {
    if (this.props.autoSlide) {
      this.intervalId = window.setInterval(() => {
        this.tick();
      }, this.tickTime);
    }
    setTimeout(() => {
      this.setMaxSlide();
    }, 1000);
    // eslint-disable-next-line no-restricted-globals
    addEventListener('resize', this.setMaxSlide);
  }

  componentWillUnmount() {
    if (this.props.autoSlide) {
      window.clearInterval(this.intervalId);
    }
    // eslint-disable-next-line no-restricted-globals
    removeEventListener('resize', this.setMaxSlide);
  }

  tick = () => {
    if (!this.blockTimerSlide) {
      this.timerTime += this.tickTime;
    }
    if (this.timerTime >= this.props.autoSlideDelay) {
      this.timerTime = 0;
      this.goRight();
    }
  };

  getMaxSlide = () => {
    const nrTilesInWidth = Math.round(this.imagesContainer.offsetWidth / this.arrayOfElemsRef[0].offsetWidth);
    return Math.max(this.arrayOfElemsRef.length / nrTilesInWidth - 1, 0);
  };

  setMaxSlide = () => {
    const maxSlides = this.getMaxSlide();
    if (maxSlides !== this.state.maxTimesSlide) {
      this.setState({ maxTimesSlide: maxSlides });
      this.handlePosSelect(0, false);
    }
  };

  goLeft = () => {
    this.setMaxSlide();

    if (this.state.activePos === 0) {
      // start new at end
      this.handlePosSelect(this.state.maxTimesSlide, true);
    } else {
      this.handlePosSelect(Math.ceil(this.state.activePos - 1), true);
    }
  };

  goRight = () => {
    this.setMaxSlide();

    if (this.state.activePos < this.state.maxTimesSlide && this.state.activePos + 1 > this.state.maxTimesSlide) {
      // partial slide
      this.handlePosSelect(this.state.maxTimesSlide, true);
    } else if (this.state.activePos === this.state.maxTimesSlide) {
      // we are at last slide, start new
      this.handlePosSelect(0, true);
    } else {
      this.handlePosSelect(this.state.activePos + 1, true);
    }
  };

  handlePosSelect = (val, calc = true) => {
    this.timerTime = 0;
    if (calc) {
      this.setMaxSlide();
    }
    if (this.state.maxTimesSlide >= val) {
      this.setState({
        activePos: val,
        imagesLeft: val * -100,
      });
    }
  };

  handleTouchStart = (evt) => {
    this.xDown = evt.touches[0].clientX;
    this.yDown = evt.touches[0].clientY;
  };

  handleTouchMove = (evt) => {
    if (!this.xDown || !this.yDown) {
      return;
    }
    const xUp = evt.touches[0].clientX;
    const yUp = evt.touches[0].clientY;

    const xDiff = this.xDown - xUp;
    const yDiff = this.yDown - yUp;

    if (Math.abs(xDiff) > Math.abs(yDiff)) {
      if (xDiff > 0) {
        this.goRight();
      } else {
        this.goLeft();
      }
    } else if (yDiff > 0) {
      /* up swipe */
    } else {
      /* down swipe */
    }
    /* reset values */
    this.xDown = null;
    this.yDown = null;
  };

  getArrow = (direction = 'left') => {
    const { leftSVGIcon } = this.props;
    let rotation = '';
    switch (direction) {
      case 'left':
        break;
      case 'right':
        // rotation = "rotate(180deg)"
        break;
      case 'up':
        rotation = 'rotate(90deg)';
        break;
      case 'down':
        rotation = 'rotate(270deg)';
        break;
      default:
        rotation = '';
    }
    if (leftSVGIcon) {
      const svg = leftSVGIcon;
      const svgChildren = svg.props.children;
      const viewBox = svg.props.viewBox;

      return (
        <svg viewBox={viewBox} style={{ transform: rotation }}>
          {svgChildren}
        </svg>
      );
    }
    return (
      <svg viewBox="0 0 17 32" style={{ transform: rotation }}>
        <path d="M14.897 31.669c0.221 0.221 0.497 0.331 0.8 0.331s0.579-0.11 0.8-0.331c0.441-0.441 0.441-1.159 0-1.6l-14.069-14.069 14.069-14.069c0.441-0.441 0.441-1.159 0-1.6s-1.159-0.441-1.6 0l-14.897 14.869c-0.441 0.441-0.441 1.159 0 1.6l14.897 14.869z" />
      </svg>
    );
  };

  render() {
    const { style, className, showButtons, buttonsClass, leftButton, rightButton } = this.props;

    /* eslint-disable object-curly-newline */
    const { leftButton: leftButtonFromState, rightButton: rightButtonFromState } = this.state;
    /* eslint-enable object-curly-newline */

    const inlineStyle = style || {};
    const containerClasses = className ? ` ${className}` : '';
    return (
      <div
        className={styles.SliderParent + containerClasses}
        ref={(myFriend) => {
          this.slider = myFriend;
        }}
        style={inlineStyle}
        onTouchMove={this.handleTouchMove}
        onTouchStart={this.handleTouchStart}
      >
        {leftButtonFromState && leftButtonFromState}
        {rightButtonFromState && rightButtonFromState}
        <div
          className={styles.imagesContainer}
          ref={(myFriend) => {
            this.imagesContainer = myFriend;
          }}
        >
          {this.state.imagesArray.map((elem, index) => (
            <div
              key={`sliderElement_${this.state.randomId}_${index}`}
              className={styles.sliderElemWrapper}
              ref={(elemRef) => {
                this.arrayOfElemsRef[index] = elemRef;
              }}
              style={{ left: `${this.state.imagesLeft}%` }}
              onMouseOver={() => {
                this.blockTimerSlide = true;
              }}
              onMouseOut={() => {
                this.blockTimerSlide = false;
              }}
            >
              {elem}
            </div>
          ))}
        </div>
        {this.state.maxTimesSlide > 0 && showButtons && (
          <span
            className={`${styles.leftButton} ${buttonsClass}`}
            onClick={() => {
              this.timerTime = 0;
              this.goLeft();
            }}
          >
            {leftButton || this.getArrow()}
          </span>
        )}
        {this.state.maxTimesSlide > 0 && showButtons && (
          <span
            className={`${styles.rightButton} ${buttonsClass}`}
            onClick={() => {
              this.timerTime = 0;
              this.goRight();
            }}
          >
            {rightButton || this.getArrow('right')}
          </span>
        )}
      </div>
    );
  }
}
